let time = 28;
let maxMistakes = 5;
let timeleft;
let timeInterval;
let mistakes;

const okGameArea = document.querySelector("#gameContainer .ok-area"),
  missGameArea = document.querySelector("#gameContainer .miss"),
  startGame = document.querySelector("#gameContainer #go"),
  retryGame = document.querySelector("#gameContainer #fail #retry"),
  okMarks = document.querySelectorAll(".ok-match"),
  countDown = document.querySelector("#gameContainer .countdown"),
  gameContainer = document.querySelector("#gameContainer.game__container"),
  timerContainer = document.querySelector("#gameContainer .timer"),
  header = document.querySelector("header"),
  triggerWindowPoint = window.innerHeight / 5,
  btnMenu = document.querySelector(".btn-menu"),
  body = document.body,
  navLinks = document.querySelectorAll("nav.nav--mobile a"),
  protect = document.querySelector("#protect"),
  protectJouer = document.querySelector(".protect__overflowed"),
  protectJouerBtn =
    protectJouer && protectJouer.querySelector(".protect__jouer"),
  protectJouerPants = protectJouer && protectJouer.querySelector("img");

function scrollHeader() {
  if (window.pageYOffset >= triggerWindowPoint) {
    header.classList.add("scroll");
  } else {
    header.classList.remove("scroll");
  }
}

scrollHeader();

window.addEventListener("scroll", scrollHeader, { passive: true });
// window.addEventListener("scroll", gameButtonHide, { passive: true });

window.addEventListener("hashchange", function (e) {
  const hash = location.hash;
  const targetAnchor = document.querySelector(hash);

  if (targetAnchor) {
    const scrollPosition = targetAnchor.offsetTop - header.clientHeight;

    window.scrollTo({
      behavior: "smooth",
      top: scrollPosition,
    });
  }
});

btnMenu.addEventListener("click", function () {
  body.classList.toggle("menu-open");
  header.classList.toggle("menu-open");
});

navLinks.forEach((link) => {
  link.addEventListener("click", (e) => {
    body.classList.toggle("menu-open");
    header.classList.toggle("menu-open");
  });
});

function gameButtonHide() {
  if (!gameContainer) return null;

  const isGameInViewport = isInViewport(gameContainer);

  // console.log(`isGameInViewport`, isGameInViewport)

  if (isGameInViewport) {
    protectJouer.classList.add("hide");
  } else {
    protectJouer.classList.remove("hide");
  }
}

function isInViewport(element) {
  const { top, bottom, height } = element.getBoundingClientRect();

  // console.log(`top, bottom`, top, bottom)

  return (
    // top >= 0 && top <= Math.min( height, (window.innerHeight || document.documentElement.clientHeight) )
    (top >= 0 &&
      top <=
        (window.innerHeight - 40 ||
          document.documentElement.clientHeight - 40)) ||
    (bottom >= height / 2 &&
      bottom <= (window.innerHeight || document.documentElement.clientHeight))
  );
}

// const protectJouerPosition = () => {
//   if (protect) {
//     const width = protect.clientWidth,
//       bodyWidth = document.documentElement.clientWidth,
//       delta = bodyWidth - width,
//       rightPosition = delta / 2,
//       bottom = 40;

//     protectJouer.style.width = `${2 * protectJouerBtn.clientWidth * 0.95}px`;
//     protectJouer.style.right = `${rightPosition}px`;
//     protectJouer.style.bottom = `${bottom}px`;

//     const { clientWidth: containerWidth } = protectJouer,
//       offsetLeft = containerWidth * 0.2,
//       imgMaxWidth = containerWidth - offsetLeft,
//       imgMaxHeight = 219;

//     protectJouerPants.style.clip = `rect(0, ${imgMaxWidth}px, ${imgMaxHeight}px, 0)`;
//   }
// };

// protectJouerPosition();

// window.addEventListener("resize", protectJouerPosition);

// if (document.body.classList.contains("home")) {
//   okGameArea.addEventListener("click", play);
//   startGame.addEventListener("click", go);
//   retryGame.addEventListener("click", retry);

//   function play(e) {
//     if (!gameContainer.classList.contains("go")) {
//       return;
//     }

//     const target = e.target;

//     // console.log(`target`, target);

//     if (target.closest(".ok-match")) {
//       target.closest(".ok-match").classList.add("show");

//       if (Array.from(okMarks).every((ok) => ok.classList.contains("show"))) {
//         sessionStorage.setItem("win", 1);
//         location.replace(
//           document.querySelector('input[name="redirect"]').value
//         );
//       }
//     } else {
//       missGameArea.classList.add("show");
//       setTimeout(function () {
//         missGameArea.classList.remove("show");
//       }, 250);
//       mistakes++;
//       if (mistakes == maxMistakes) fail();
//     }
//   }
//   function calcTime(offset) {
//     const d = new Date();
//     const utc = d.getTime() + d.getTimezoneOffset() * 60000;
//     const nd = utc + 3600000 * offset;
//     return nd;
//   }
//   function go() {
//     const dateOffset = new Date("2021-12-19T23:59:00");
//     if (calcTime("+3") > Date.parse(dateOffset)) {
//       document.querySelector("#message-time").style.display = "block";
//       return false;
//     }
//     gameContainer.classList.add("ready");
//     countDown.innerHTML = "";
//     setTimeout(set3, 250);
//     setTimeout(set2, 1250);
//     setTimeout(set1, 2250);
//     setTimeout(setGo, 3250);
//     function set3() {
//       countDown.textContent = 3;
//     }
//     function set2() {
//       countDown.textContent = 2;
//     }
//     function set1() {
//       countDown.textContent = 1;
//     }
//     function setGo() {
//       gameContainer.classList.remove("ready");
//       gameContainer.classList.add("go");
//       timeleft = time;
//       mistakes = 0;
//       timerContainer.textContent = time;
//       timeInterval = setInterval(timer, 1000);
//     }
//   }
//   function retry() {
//     gameContainer.classList.remove("fail");
//     timerContainer.textContent = "";
//     go();
//   }
//   function timer() {
//     if (timeleft >= 1) {
//       timeleft -= 1;
//       timerContainer.textContent = timeleft;
//     } else {
//       fail();
//     }
//   }
//   function fail() {
//     clearInterval(timeInterval);
//     gameContainer.classList.remove("go");
//     gameContainer.classList.add("fail");
//     countDown.textContent = "";
//     okMarks.forEach((mark) => mark.classList.remove("show"));
//   }
// }
