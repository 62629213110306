if (document.body.classList.contains("page-template-page-win")) {
  jQuery(document).ready(function ($) {
    $.validate({
      form: "#contest-form",
      modules: "security",
      borderColorOnError: false,
      validateOnBlur: true,
      errorMessagePosition: "inline",
      scrollToTopOnError: false,
    });
  });

  // jQuery(document).ready(function( $ ) {
  //   console.log($('#contest-form'))

  // });

  // const form = document.querySelector('#contest-form'),
  const maskedInputs = document.querySelectorAll("[data-masked]");

  // form.addEventListener('submit', function(e){

  //   console.log(`this`, this);

  //   const dataToValidate = {};

  //   document.querySelectorAll('[data-validation]').forEach(el =>  {
  //     if (el.type === 'radio' || el.type === 'checkbox')
  //       dataToValidate[el.name] ? '' : dataToValidate[el.name] = el.checked
  //     if (el.type !== 'radio' && el.type !== 'checkbox')
  //       dataToValidate[el.name] ? '' : dataToValidate[el.name] = el.value
  //   })

  //    for (const key in dataToValidate) {
  //      const value = dataToValidate[key]
  //      console.log(`value`, value);

  //      const messageContainer = document.querySelector(`[data-message-for="${key}"]`);

  //       if (!value) {
  //         if (messageContainer) {
  //           messageContainer.textContent = 'Error';
  //         }

  //       } else {
  //         if (messageContainer) {
  //           messageContainer.textContent = '';
  //         }
  //       }
  //    }

  //    console.log(`dataToValidate`, dataToValidate)

  //    e.preventDefault()

  //   // const data = Object.fromEntries([...new FormData(this).entries()])

  //   if (this.classList.contains('formhandler--is-not-valid')) {
  //     e.preventDefault();
  //   }
  // })

  maskedInputs.forEach((input) => {
    const maskValue = input.dataset.masked;

    const maskOptions = {
      mask: maskValue === "Number" ? Number : maskValue,
    };

    const mask = IMask(input, maskOptions);

    if (input.id === "mobile_number") {
      mask.on("complete", function () {
        document.querySelector("#birthday_day").focus();
      });
    }
    if (input.id === "birthday_day") {
      // mask.updateOptions({
      //   mask: '00',
      // })

      mask.on("complete", function () {
        document.querySelector("#birthday_month").focus();
      });
    }
    if (input.id === "birthday_month") {
      // mask.updateOptions({
      //   mask: '00',
      // })

      mask.on("complete", function () {
        document.querySelector("#birthday_year").focus();
      });
    }

    // if (input.id === 'birthday_year') {
    //   mask.updateOptions({
    //     mask: '0000',
    //     min: 1900,
    //     max: 2100,
    //   })
    // }
  });

  // const formhandler = new FormHandler({
  //   fields: {
  //     first_name: {
  //       validation: 'isName',
  //     },
  //     last_name: {
  //       validation: 'isName',
  //     },
  //     phone: {
  //       validation: 'isPhone'
  //     },
  //     birthday_date: {
  //       validation: 'isNonEmpty',
  //     },
  //     email: {
  //       validation: 'isEmail',
  //     },
  //     i_agree: {
  //       validation: 'isCheckboxCheked'
  //     }
  //   },
  // })

  // console.log(`formhandler`, formhandler)
}
